import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'calculator/';
  }

  calculate(data: any) {
    return this.http.post(this.getUrl(), data)
      .pipe(map(res => {
        return res;
      }));
  }

  noApplication(data: any) {
    return this.http.post(this.getUrl() + 'no-application', data)
      .pipe(map(res => {
        return res;
      }));
  }

  calculateScorecard(data: any): Observable<any> {
    return this.http.post<any>(this.getUrl() + 'scorecard', data)
  }
}
