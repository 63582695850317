import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { environment } from './../../environments/environment';
import { AuthService } from './authentication/auth.service';
import { LoaderService, AlertService } from './services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StayAliveDialogComponent } from '@shared/stay-alive-dialog/stay-alive-dialog.component';

@Injectable()
export class HttpIntercept implements HttpInterceptor {

  constructor(
    public dialog: MatDialog,
    private authenticationService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludePath = /(?:login|resetpassword|logo|link\/resend)+/g;
    const includePath = /(?:login-as|logout-as)+/g;
    const pollingPath = /(?:updates-count|exports)+/g;

    if (request.url.search(excludePath) === -1 || request.url.search(includePath) > -1) {
      if (request.url.search(pollingPath) === -1) {
        this.authenticationService.extendCookie();
      } else {
        const cookieLifeRemaining = (new Date(JSON.parse(this.cookieService.get('currentUser')).expiryTime).getTime() - new Date().getTime()) / 1000 / 60;
        if (cookieLifeRemaining < 2) {
          this.openStayAliveDialog();
        }
      }

      if(!this.excludedUrls(request)) {
        this.loaderService.startLoader();
      }

      const headers = {};

      if (localStorage.getItem('token')) {
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      }

      request = request.clone({
        setHeaders: headers
      });
    }

    request = request.clone({
      url: this.updateUrl(request.url),
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (this.ensureAllowedServerAlertsRequestMethods(request) && !this.excludedUrls(request)) {
            this.alertService.success(null, 'Close');
          }
          this.returnResponse(request);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (!(error.error instanceof ErrorEvent)) {
          if (this.ensureAllowedServerAlertsRequestMethods(request) && !this.excludedUrls(request)) {

            //When deleting a payment and a validation exception occurs, display the return message to the user
            if(request.url.startsWith('api/fundings') && request.method === 'DELETE'){
              this.alertService.error(error.error[0], 'Close', 6000);
            } else {
              this.alertService.error(null, 'Close');
            }
          }
        }
        this.catchErrors(error);
        this.returnResponse();
        return throwError(error.error);
      }))
  }

  private catchErrors(error: HttpErrorResponse) {
    const applicationBaseUrlPattern = /\/api\/applications\/\d+$/;
    const scorecardServiceError = error.url.includes('/scorecard/') && !error.url.endsWith('/scorecard')/** scorecard from moneythumb ends with /scorecard  */

    if ([401, 403].indexOf(error.status) !== -1) {
      this.authenticationService.logout()
        .subscribe((r: any) => {
          this.killSession();
        },
          (error) => {
            this.killSession();
          });
    } else if ([504].indexOf(error.status) !== -1) {
      this.alertService.error('Server timed out. Reload and try again.', 'Close', 8000);
    } else if ([400].indexOf(error.status) !== -1) {
      if (Array.isArray(error.error)) {
        this.alertService.error(error.error[0], 'Close', 15000);
      } else if (scorecardServiceError) {
        this.alertService.error(error.error.body, 'Close', 15000);
      }
      else {
        this.alertService.error(error.error.message || error.error, 'Close', 8000);
      }
    } else if([404].indexOf(error.status) !== -1 && applicationBaseUrlPattern.test(error.url)) {
      this.alertService.error(error.error, 'Close', 15000)
      setTimeout(() => {
        this.router.navigate(['/home']);
      }, 3000)
    } else if ([502].indexOf(error.status) !== -1 && scorecardServiceError) {
      this.alertService.error("Something went wrong, please check Scorecard page", 'Close', 15000);
    }
  }

  private ensureAllowedServerAlertsRequestMethods(request): boolean {
    const allowedMethods: Array<string> = ['PUT', 'DELETE', 'POST', 'PATCH'];
    return allowedMethods.includes(request.method);
  }

  private excludedUrls(request): boolean {
    const excludePaths = /(?:login|logout|login-as|resetpassword|attachments|calculator|calendar|search|exports)+/g;
    return request.url.search(excludePaths) > -1;
  }

  updateUrl(req: string) {
    
    //if it is not local then we are removing /api
    if((
        (
          environment.production === true || 
          environment.staging === true || 
          environment.development === true
        )
      ) && 
        ( 
          req.startsWith('clear/') || 
          req.startsWith('calendar/') || 
          req.startsWith('moneythumb/') || 
          req.startsWith('report/') || 
          req.startsWith('crs/') || 
          req.startsWith('plaid/') || 
          req.startsWith('scorecard/')
        )
      ) {
      return environment.servicesUrl.slice(0, -4) + req;
    }
    if (req.startsWith('https')) {
      return req;
    }
    return environment.servicesUrl + req;
  }

  killSession() {
    this.authenticationService.killSession();
    this.router.navigate(['/login']);
  }

  returnResponse(request?: any) {
    if (request) {
      if(!this.excludedUrls(request)) {
        this.loaderService.stopLoader();
      }
    } else {
      this.loaderService.stopLoader();
    }
  }

  openStayAliveDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(StayAliveDialogComponent , dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'extend') {
        this.authenticationService.extendCookie();
      } else {
        this.authService.logout()
          .subscribe((r: any) => {
            document.cookie.split(';').forEach(
              function (c) {
                document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
              }
            );
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        });
      }
    });
  }

}
