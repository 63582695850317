import { Directive, OnInit, Input, ViewContainerRef, TemplateRef, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Directive({
  selector: '[authorizeRole]'
})
export class AuthorizeRoleDirective implements OnInit, OnDestroy {

  @Input() authorizeRole: Array<string>;
  @Input() authorizeRoleAdditionalParam?: boolean;

  stop = new Subject();

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.isMimicUserLoggedIn.subscribe(loggedInAs => {
      if (this.authService.getUser()) {
        this.resolveRoles();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.authorizeRoleAdditionalParam && changes.authorizeRoleAdditionalParam.currentValue !== undefined) {
      this.resolveRoles();
    }
  }

  resolveRoles() {
    // Check if additionalParam is explicitly set to false
    if (this.authorizeRoleAdditionalParam === false) {
      this.viewContainerRef.clear(); // Clear the view if additionalParam is false
      return;
    }

    // If additionalParam is true or does not exist, proceed with role checking
    if (!this.authorizeRole) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.authService.returnRolesArrayObs().pipe(
        takeUntil(this.stop)
      ).subscribe(roles => {
        if (!roles) {
          this.viewContainerRef.clear();
        } else if (roles.some(r => this.authorizeRole.includes(r))) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear(); // Clear the view if roles do not match
        }
      });
    }
  }

  ngOnDestroy() {
    this.stop.next();
  }
}
