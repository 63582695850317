import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationService, TransmissionService } from '@core/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'oiq-syndication-fund-dialog',
  templateUrl: './syndication-fund-dialog.component.html',
  styleUrls: ['./syndication-fund-dialog.component.scss']
})
export class SyndicationFundDialogComponent implements OnInit {

  whiteLabelId: number;
  fundingBankId: number;
  fundingTransmissionMethod: string;
  fundingTransmissionProcessor: any;
  processorAccountName: string;
  fundingTransmissionProcessorRequired: boolean;
  fundingBankVisible = false;
  fundingBankRequired: boolean;
  transmissionMethods = [];
  transmissionProcessors = [];
  transmissionProcessorsList = [];
  transmissionMethodList = [];
  isCalculatingAmountBasedOnSyndicationCalculationLogic = false
  calculatedAmountBasedOnSyndicationCalculationLogic = 0;

  constructor(
    private transmissionService: TransmissionService,
    private applicationService: ApplicationService,
    public dialogRef: MatDialogRef<SyndicationFundDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.whiteLabelId = this.data.application?.whiteLabelId;
    this.transmissionService.getProcessorsWithMultipleAccounts(this.whiteLabelId, 'Syndicator_Funding_Tenant')
      .subscribe((r: any) => {
        this.transmissionMethods = r;
        this.setTransmissionMethodList();
      });

   this.fundingTransmissionMethod = this.data.offer.syndicator.fundingTransmissionMethod;

   if (this.fundingTransmissionMethod === 'ACH' && (this.fundingTransmissionProcessor !== 'Manual' && this.fundingTransmissionProcessor !== 'Other')) {
    this.fundingBankVisible = true;
    this.transmissionProcessorsList = this.transmissionProcessors;
   } else if (this.data.offer.syndicator.fundingTransmissionMethod === 'Syndicator_Wallet') {
    this.fundingTransmissionProcessor = 'Other';
    this.fundingBankVisible = false;
    this.fundingBankRequired = false;
   }

   this.calculateAmountBasedOnSyndicationCalculationLogic();
  }

  setTransmissionMethodList() {
    this.transmissionMethods.map((obj: any) => {
      this.transmissionMethodList.push({
        name: obj.name.replace('_', ' '),
        value: obj.name
      });
    });
    this.setTransmissionMethodProcessors(this.fundingTransmissionMethod);
  }

  onFundingBankSelected(event) {
    this.fundingBankId = event;
  }

  onFundingTransmissionMethodChanged(event) {
    this.setTransmissionMethodProcessors(event.value);
    this.fundingTransmissionProcessor = '';
    this.processorAccountName = null;

    if (this.fundingTransmissionMethod === 'ACH') {
      this.transmissionProcessorsList = this.transmissionProcessors
      this.fundingTransmissionProcessorRequired = true;
      this.fundingTransmissionProcessor = '';
    } else if (this.fundingTransmissionMethod === 'Syndicator_Wallet') {
      this.fundingTransmissionProcessor = 'Other';
      this.fundingBankVisible = false;
      this.fundingBankRequired = false;
    } else {
      this.fundingBankVisible = false;

      this.fundingTransmissionProcessorRequired = false;

      this.fundingBankId = null;
      this.fundingBankRequired = false;

      this.fundingTransmissionProcessor = 'Manual';
    }
  }

  setTransmissionMethodProcessors(methodName: string) {
    this.transmissionProcessors = this.transmissionMethods.filter((method: any) => method.name === methodName)[0].processors;
    if (this.fundingTransmissionMethod === 'ACH' && this.transmissionProcessors) {
      const processor = this.transmissionProcessors.find((processor: any) => processor.processor === this.data.offer.syndicator.fundingTransmissionProcessor && processor.defaultProcessor);
      if (processor) {
        this.fundingTransmissionProcessor = processor;
        this.processorAccountName = processor.processorAccountName;
      } else {
        this.fundingTransmissionProcessor = this.transmissionProcessors.find((processor: any) => processor. processor === this.data.offer.syndicator.fundingTransmissionProcessor)
      }
    } else if (this.transmissionProcessors){
      this.fundingTransmissionMethod = this.data.offer.syndicator.fundingTransmissionMethod;
      this.fundingTransmissionProcessor = this.transmissionProcessors.find((processor: any) => processor.processor === this.data.offer.syndicator.fundingTransmissionProcessor)
    }
  }

  onFundingTransmissionProcessorChanged(event) {
    if (this.fundingTransmissionProcessor !== 'Other' && this.fundingTransmissionProcessor !== 'Manual') {
      this.fundingBankVisible = true;
      this.fundingBankRequired = true;
    } else {
      this.fundingBankVisible = false;
      this.fundingBankRequired = false;
    }
  }

  confirm() {
    let syndication: any;
    if (this.fundingTransmissionProcessor?.automated) {
      syndication = {
        fundingBankId: this.fundingBankId,
        fundingTransmissionMethod: this.fundingTransmissionMethod,
        fundingTransmissionProcessor: this.fundingTransmissionProcessor?.processor,
        processorAccountName: this?.processorAccountName
      };
    } else {
      syndication = {
        fundingBankId: this.fundingBankId,
        fundingTransmissionMethod: this.fundingTransmissionMethod,
        fundingTransmissionProcessor: this.fundingTransmissionProcessor?.processor || 'Other',
      };
    }
    this.dialogRef.close({
      data: syndication
    });
  }

  calculateAmountBasedOnSyndicationCalculationLogic(){
    this.isCalculatingAmountBasedOnSyndicationCalculationLogic = true;
     this.applicationService.calculateAmountBasedOnSyndicationCalculationLogic(this.data.application.id, this.data.offer.id)
     .pipe(finalize(()=> this.isCalculatingAmountBasedOnSyndicationCalculationLogic = false))
     .subscribe((syndicatorPaymentAmount: number) => this.calculatedAmountBasedOnSyndicationCalculationLogic = syndicatorPaymentAmount);
  }

  selectProcessor(event, processorName) {
    if (event.isUserInput) {
      this.processorAccountName = processorName;
    }
  }
}
