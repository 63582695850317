import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, interval, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends AbstractService {

  private _client: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public client$ = this._client.asObservable();

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  get client(): BehaviorSubject<any> {
    return this._client;
  }

  getUrl(): string {
    return 'clients/';
  }

  setClientObject(data: any) {
    this._client.next(data);
  }

  listByCriteria(ids: string, name: string, ownerName: string, whiteLabelId: number, ssnLastFour: string, phoneNumber: string, email: string, fundingAmountStart: number, fundingAmountEnd: number, isoIds: Array<any>, clientLegalName?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (name) { params['name'] = name.toString(); }
    if (ownerName) { params['ownerName'] = ownerName.toString(); }
    if (whiteLabelId) { params['filterWhiteLabelId'] = whiteLabelId; }
    if (ssnLastFour) { params['ssnLastFour'] = ssnLastFour.toString(); }
    if (phoneNumber) { params['phoneNumber'] = phoneNumber.toString(); }
    if (email) { params['email'] = email.toString(); }
    // if (fundingAmountStart) { params['fundingAmountStart'] = fundingAmountStart.toString(); }
    // if (fundingAmountEnd) { params['fundingAmountEnd'] = fundingAmountEnd.toString(); }
    if (isoIds) { params['isoIds'] = isoIds.join(','); }
    if (clientLegalName) { params['clientLegalName'] = clientLegalName.toString(); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  addContact(id: number, contactId: number) {
    return this.http.put(this.getUrl() + `${id}/contacts/${contactId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  //NOTE: this is the api that adds contact to client with addresses
  patchContact(id: number, contactId: number) {
    return this.http.patch(this.getUrl() + `${id}/contacts/${contactId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  setPrimaryContact(id: number, contactId: number) {
    return this.http.put(this.getUrl() + `${id}/contacts/${contactId}/primary`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  addOwner(id: number, contactId: number, data?: any) {
    return this.http.put(this.getUrl() + `${id}/owners/${contactId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  removeOwner(id: number, contactId: number) {
    return this.http.delete(this.getUrl() + `${id}/owners/${contactId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  setPrimaryOwner(id: number, contactId: number) {
    return this.http.put(this.getUrl() + `${id}/owners/primary/${contactId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  listPayments(id: number, applicationId: number) {
    return this.http.get(this.getUrl() + `${id}/payments?applicationId=${applicationId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  listAttachments(id: number, source: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (source) { params['source'] = source; }

    return this.http.get(this.getUrl() + `${id}/attachments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listApplicationAttachments(id: number, source: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (source) { params['source'] = source; }

    return this.http.get(this.getUrl() + `${id}/applications/attachments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getNotificationSettings(clientId: number) {
    return this.http.get(this.getUrl() + `${clientId}/notifications/settings`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateNotificationSettings(clientId: number, data: any) {
    return this.http.put(this.getUrl() + `${clientId}/notifications/settings`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  listContacts(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/contacts`)
      .pipe(map(res => {
        return res;
      }));
  }

  listBanks(id: number) {
    return this.http.get(this.getUrl() + `${id}/banks`)
      .pipe(map(res => {
        return res;
      }));
  }

  getOwnersByClientId(clientId: number) {
    return this.http.get(this.getUrl() + `${clientId}/owners`)
      .pipe(map(res => {
        return res;
      }));
  }

  pollSmsMessages(clientId: number, contactId: number): Observable<any> {

    const params = {};
    params['sortDir'] = 'ASC';

    return timer(0, 5000).pipe(
      switchMap(() => this.http.get(this.getUrl() + `${clientId}/sms-threads/contacts/${contactId}/sms-messages`, { params: params })),
      map(res => {
        return res;
      }));
  }

  postSmsMessage(clientId: number, smsThreadId: number, smsMessageDto: any) {
    return this.http.post(this.getUrl() + `${clientId}/sms-threads/${smsThreadId}/sms-messages`, smsMessageDto)
      .pipe(map(res => {
        return res;
      }));
  }

  getSmsThreads(clientId: number, contactId: number) {
    return this.http.get(this.getUrl() + `${clientId}/sms-threads/contacts/${contactId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  saveNote(id: number, data: any, noteId?: number) {
    if (noteId) {
      return this.http.put(this.getUrl() + `${id}/notes/${noteId}`, data)
        .pipe(map(res => {
          return res;
        }));
    } else {
      return this.http.post(this.getUrl() + `${id}/notes`, data)
        .pipe(map(res => {
          return res;
        }));
    }
  }

  getNotes(id: number, category?: string) {
    let url = this.getUrl() + `${id}/notes`;

    if (category) {
      url += '?category=' + category;
    }

    return this.http.get(url)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteNote(clientId: number, noteId: number) {
    return this.http.delete(this.getUrl() + `${clientId}/notes/${noteId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getWebLinks(id: number) {
    let url = this.getUrl() + `${id}/websites`;

    return this.http.get(url)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteWebLink(clientId: number, linkId: number) {
    return this.http.delete(this.getUrl() + `${clientId}/websites/${linkId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  saveWebLink(id: number, data: any, linkId?: number) {
    if (linkId) {
      return this.http.put(this.getUrl() + `${id}/websites/${linkId}`, data)
        .pipe(map(res => {
          return res;
        }));
    } else {
      return this.http.post(this.getUrl() + `${id}/websites`, data)
        .pipe(map(res => {
          return res;
        }));
    }
  }

  patchClientData(clientId: number, data: any) {
    return this.http.patch(this.getUrl() + `${clientId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  listMinApplicationsByCriteria(clientId: number, statuses: string, fundingStatuses: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (statuses) { params['statuses'] = statuses; }
    if (fundingStatuses) { params['fundingStatuses'] = fundingStatuses; }

    return this.http.get(this.getUrl() + `${clientId}/applications-minimal`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listMinFundingsByCriteria(clientId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${clientId}/fundings-minimal`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

}
